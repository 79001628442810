body {
  font-family: "Equip-Regular";
}

.dropzone-section {
  position: relative;
}

.dropzone {
  background-color: #f7f7fa;
  transition: background-color 0.22s ease-in-out;
  outline: none;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropzone-dark {
  background-color: rgb(26, 27, 31) !important;
}

.dropzone:hover {
  background-color: #f2f2f2;
}

.dropzone-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  opacity: 0.3;
}

.close-button {
  position: absolute;
  top: -25px;
  right: 0px;
  border: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dropzone-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.preview {
  max-width: 100%;
  max-height: 100%;
}

.logo-cropper {
  position: relative;
  width: 100%;
  max-width: 100%;
  height: 0;
  padding-bottom: 100%;
}

.cswitch-light-mode input {
  filter: hue-rotate(0deg);
}